import "katex/dist/katex.min.css"
import "../styles/code.scss"

import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./post.module.scss"

export const query = graphql`
  query($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "MMM. DD, YYYY")
        twitterLink
        coverImageUrl
        description
      }
      timeToRead
      wordCount {
        words
      }
      html
      fileAbsolutePath
    }
  }
`

const Post = (props) => {
  return (
    <Layout>
      <SEO
        title={props.data.markdownRemark.frontmatter.title}
        image={props.data.markdownRemark.frontmatter.coverImageUrl}
        description={props.data.markdownRemark.frontmatter.description}
      />
      <div className={styles.post}>
        <div className={styles.title}>
          <h1>{props.data.markdownRemark.frontmatter.title}</h1>
          {!props.data.markdownRemark.fileAbsolutePath.includes(
            "collections"
          ) &&
            !props.data.markdownRemark.fileAbsolutePath.includes("about") &&
            !props.data.markdownRemark.fileAbsolutePath.includes("artwork") && (
              <div className={styles.info}>
                {!props.data.markdownRemark.fileAbsolutePath.includes(
                  "writing"
                ) && <div>{props.data.markdownRemark.frontmatter.date}</div>}
                {!props.data.markdownRemark.fileAbsolutePath.includes(
                  "videos"
                ) && (
                  <>
                    <div>{props.data.markdownRemark.wordCount.words} words</div>
                    <div>
                      {props.data.markdownRemark.timeToRead} minute read
                    </div>
                  </>
                )}
              </div>
            )}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
        ></div>
        {props.data.markdownRemark.frontmatter.twitterLink && (
          <div className={styles.twitter}>
            <a
              href={props.data.markdownRemark.frontmatter.twitterLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              twitter thread
            </a>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Post
